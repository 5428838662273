<template>
    <div>
        <template v-if="showFilters">
            <b-card no-body class="mb-2 p-1">
                <div class="row">
                    <b-col v-b-toggle.filters cols="6">
                        <span
                            style="display: inline-block; font-weight: 600; font-size: 18px; line-height: 44px; margin-left: 10px;">
                            <template v-if="!filters.shift && !filters.date">Ничего не выбрано</template>
                            <template v-if="filters.date">{{ formattedDate }}</template>
                            <template v-if="filters.date && filters.shift"> / </template>
                            <template v-if="filters.shift">{{ filters.shift.name }}</template>

                            <template v-if="filters.storageTo || filters.storageFrom"> / </template>

                            <template v-if="filters.storageFrom">из {{ filters.storageFrom.name }} </template>
                            <template v-if="filters.storageTo">в {{ filters.storageTo.map((storage) => storage.name).join(', ') }}</template>
                        </span>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <b-button variant="primary" @click="applyFilters" class="mr-1">
                            <b-icon-arrow-repeat font-scale="1.2"/>
                        </b-button>
                    </b-col>
                </div>

                <b-collapse id="filters" class="d-print-none">
                    <b-card-body>
                        <b-row>
                            <div class="col-md-3">
                                <b-form-group description="Дата выдачи">
                                    <b-input-group>
                                        <datetime type="date" class="form-control no-border"
                                                  v-model="filters.date"></datetime>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                            <div class="col-md-3">
                                <b-form-group description="Смена">
                                    <shift-input v-model="filters.shift"></shift-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-3">
                                <b-form-group description="Откуда">
                                    <storage-picker
                                        v-model="filters.storageFrom"
                                        :allowed-roles="[ROLE_STOCKMAN]"
                                        selectable-for-task-list
                                        nullable
                                        material
                                    />
                                </b-form-group>
                            </div>
                            <div class="col-md-3">
                                <b-form-group description="Куда">
                                    <storage-select
                                        v-model="filters.storageTo"
                                        multiple
                                        selectable-for-task-list
                                        open-direction="bottom"
                                    />
                                </b-form-group>
                            </div>

                        </b-row>
                        <b-row>

                            <div class="col-md-3">
                                <b-form-group description="Отображать только не полностью выполненные задания">
                                    <b-checkbox v-model="filters.notDeliveredOnly">Только не выданное</b-checkbox>
                                </b-form-group>
                            </div>
                            <div class="col-md-9">
                                <b-form-group description="План производства">
                                    <production-plan-input v-model="filters.productionPlan"></production-plan-input>
                                </b-form-group>
                            </div>

                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </template>
        <template v-if="!loading && data && data.length">
            <b-card v-for="(row, row_index) in data" :key="getRowKey(row)" no-body>
                <b-card-header v-if="showTableHeadings" style="font-weight: bold">
                    <span
                        style="margin-right: .5rem">{{ row.storageFrom ? row.storageFrom.name : '[неизвестно]' }}</span>
                    <i class="fas fa-long-arrow-alt-right"></i> {{ row.storageTo.name }}
                    <span class="pull-right">
                        <button class="btn btn-sm"
                                :class="{'btn-outline-success': !storageBeingSelected(row_index), 'btn-success': storageBeingSelected(row_index)}"
                                v-if="allowTransfer"
                                @click="toggleStorageSelect(row_index)"
                        >Выбрать</button>
                        <button
                            v-if="allowTransfer && storageBeingSelected(row_index) && storageSelectionNotEmpty(row_index)"
                            @click="transferRow(row_index)"
                            class="btn btn-sm btn-success">
                            <i class="fas fa-share-square"></i>
                            Передать
                        </button>
                    </span>
                </b-card-header>
                <div class="card-body" style="padding: 0 !important;">
                    <table class="table table-bordered table-sm" style="margin-bottom: 0">
                        <thead class="thead-dark">
                        <tr style="font-size: small">
                            <th v-if="storageBeingSelected(row_index)"></th>
                            <th></th>
                            <th>Ном-ра</th>
                            <th>Ед. изм.</th>
                            <th>Отгрузка</th>
                            <th>Необх.</th>
                            <th>Выдано</th>
                            <th>Осталось выдать</th>
                            <th>Коррекция</th>
                            <th></th>
                            <th v-if="allowTransfer && !storageBeingSelected(row_index)"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(amount, amount_index) in row.amounts"
                            @dblclick="transfer(amount)"
                            :class="{
                                    'yellow': !datesEqual(amount.production_plan_date, filters.date),
                                    'alert-info': amountIsSelected(row_index, amount_index),
                                    'blocked': transferIsBlocked(amount.production_plan_date)
                                }">
                            <td v-if="storageBeingSelected(row_index)" class="text-center">
                                <b-form-checkbox v-if="canSelectAmount(row_index, amount_index)"
                                                 @change="toggleAmountSelect(row_index, amount_index)"></b-form-checkbox>
                            </td>
                            <td class="text-center"><i style="cursor: pointer" @click="productionPlansFor = amount"
                                                       class="fas fa-question-circle"></i></td>
                            <td>{{ amount.nomenclature.name }}</td>
                            <td>{{ amount.nomenclature.measure_unit.name }}</td>
                            <td :class="{'bold': !datesEqual(amount.production_plan_date, filters.date)}">
                                <div class="text-center" style="white-space: nowrap;">
                                    <span>{{ moment(amount.production_plan_date).format('DD.MM.YYYY') }}</span>
                                    <template v-if="lastShippedDateLoading">
                                        <i class="fas fa-spinner fa-spin"/>
                                    </template>
                                    <template v-else-if="transferIsBlocked(amount.production_plan_date)">
                                        <i class="fas fa-exclamation-triangle" style="color: orange;" v-b-tooltip
                                           title="На эту дату уже все отгружено!"/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-exclamation-triangle" style="visibility: hidden;"/>
                                    </template>
                                </div>
                            </td>
                            <td class="text-right">
                                {{ required(amount).toFixed(3) }}
                            </td>
                            <td class="text-right">
                                {{ delivered(amount).toFixed(3) }}
                            </td>
                            <td class="text-right">
                                {{ delta(amount).toFixed(3) }}
                            </td>

                            <td class="text-right">{{ parseFloat(amount.correction_count).toFixed(3) }}</td>
                            <td class="text-center" style="vertical-align: middle">
                                <button class="btn btn-outline-success btn-sm"
                                        @click="openCorrection(amount, row_index, amount_index)">
                                    <i class="fas fa-cog"></i>
                                </button>
                            </td>
                            <td
                                v-if="allowTransfer && !storageBeingSelected(row_index) && !transferIsBlocked(amount.production_plan_date)"
                                class="text-center"
                                style="vertical-align: middle">
                                <button
                                    class="btn btn-success btn-sm"
                                    @click="transfer(amount)">
                                    <i class="fas fa-share-square"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </b-card>
        </template>
        <b-alert v-else-if="!filters.date" variant="warning" :show="!filters.date">Выберите дату</b-alert>
        <b-alert v-else-if="!filters.shift" variant="warning" :show="!filters.shift">Выберите смену</b-alert>
        <div v-else-if="loading" class="text-center">
            <div class="alert alert-info mt-2" style="display: inline-block"><i class="fas fa-spinner fa-spin"></i>
            </div>
        </div>
        <b-alert v-else variant="info" :show="true" class="text-center">Нет данных</b-alert>
        <modal
            name="productionPlans"
            :adaptive="true"
            :scrollable="true"
            :height="'auto'"
            :maxHeight="300"
            @before-close="productionPlansFor = null"
        >
            <div v-if="!!productionPlansFor && productionPlansFor.production_plan_ids.length">
                <b-card no-body style="margin-bottom: 0 !important;">
                    <b-card-header style="font-weight: bold;">

                        <span class="pull-right"><button @click="$modal.hide('productionPlans')"
                                                         class="btn btn-sm btn-default"><i
                            class="fas fa-times"></i></button></span>
                    </b-card-header>
                    <b-card-body>
                        <b-form-group>
                            <div style="margin-bottom: .5rem">Производственные планы для
                                {{ productionPlansFor.nomenclature.name }}
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-sm" style="margin-bottom: 0">
                                    <thead class="thead-dark">
                                    <tr>
                                        <th>№</th>
                                        <th>Номенклатура</th>
                                        <th style="white-space: nowrap">Кол-во</th>
                                        <th>Начало</th>
                                        <th>Отгрузка</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="id in productionPlansFor.production_plan_ids">
                                        <td>{{ id }}</td>
                                        <td>{{ productionPlans[id].nomenclature.name }}</td>
                                        <td style="white-space: nowrap;">
                                            {{ map[productionPlansFor.id][productionPlansFor.storage_id][id].count }}
                                            {{ productionPlansFor.nomenclature.measure_unit.name }}
                                        </td>
                                        <td>
                                            {{
                                                moment(map[productionPlansFor.id][productionPlansFor.storage_id][id].production_start_at).format('DD.MM.YYYY HH:mm')
                                            }}
                                        </td>
                                        <td>{{ moment(productionPlans[id].date).format('DD.MM.YYYY HH:mm') }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </b-form-group>
                        <b-form-group>
                            <button class="btn btn-outline-danger btn-sm" @click="$modal.hide('productionPlans')">
                                Закрыть
                            </button>
                        </b-form-group>
                    </b-card-body>
                </b-card>
            </div>
        </modal>
    </div>
</template>

<script>
import ShiftInput from '@components/_common/ShiftInput';
import StoragePicker from '@components/_common/StoragePicker';
import StorageSelect from '@components/_common/StorageSelect';
import lsfMixin from '@utils/localStorageFilterMixin'
import moment from 'moment';
import SuccessMessage from "@components/_common/SuccessMessage";
import {Datetime} from 'vue-datetime';
import ProductionPlanInput
    from '@components/_common/ProductionPlanInput';
import {PRODUCTION_PLANS_ENDPOINT, STORAGE_TASKS_ENDPOINT} from '@utils/endpoints';
import {ROLE_STOCKMAN} from "@utils/Roles";
import CreateCorrection
    from '@components/materialtransferact/correction/CreateCorrection';
import {StorageService} from '@services';

const FILTER_PATTERN = {
    date: null,
    shift: null,
    storageFrom: [],
    storageTo: [],
    notDeliveredOnly: false,
    productionPlan: null,
};

export default {
    name: 'StorageTaskList',
    components: {
        ShiftInput,
        datetime: Datetime,
        SuccessMessage,
        StoragePicker,
        StorageSelect,
        ProductionPlanInput,
    },
    mixins: [lsfMixin],
    props: {
        date: {
            type: String,
            required: false,
            default: null,
        },
        shift: {
            type: Object,
            required: false,
            default: null,
        },
        storageTo: {
            type: Object,
            required: false,
            default: null,
        },
        storageFrom: {
            type: Object,
            required: false,
            default: null
        },
        groupByStorageFrom: {
            type: Boolean,
            required: false,
            default: true
        },
        showFilters: {
            type: Boolean,
            required: false,
            default: true
        },
        showTableHeadings: {
            type: Boolean,
            required: false,
            default: true
        },
        allowTransfer: {
            type: Boolean,
            required: false,
            default: true
        },
        notDeliveredOnly: {
            type: Boolean,
            required: false,
            defaut: null,
        }
    },
    computed: {
        formattedDate() {
            return moment(this.filters.date).format('DD.MM.YY');
        },
    },
    watch: {
        filters: {
            handler: function () {
                this.watchFilters(this.filters);
            },
            deep: true,
        },
        productionPlansFor() {
            if (!!this.productionPlansFor) {
                this.$modal.show('productionPlans');
            }
        },
        storageTo() {
            this.initProps();
        },
        date() {
            this.initProps();
        },
        shift() {
            this.initProps();
        },
    },
    created() {
        this.initFilters(this, FILTER_PATTERN);
        this.initProps();
    },
    mounted() {
        this.fetchStorages();
        this.fetchLastShippedDate();
    },
    data() {
        return {
            ROLE_STOCKMAN,
            availableStorages: [],
            filters: {
                date: null,
                shift: null,
                storageFrom: null,
                storageTo: [],
                notDeliveredOnly: false,
                productionPlan: null,
            },
            data: [],
            productionPlans: {},
            map: {},
            loading: false,
            productionPlansFor: null,
            lastShippedDate: null,
            lastShippedDateLoading: false,
            selected: {}
        };
    },
    methods: {
        initProps() {
            if (!!this.storageTo) {
                this.filters.storageTo = [this.storageTo];
            }

            if (!!this.storageFrom && !!this.groupByStorageFrom) {
                this.filters.stroageFrom = this.storageFrom;
            }

            if (!!this.date) {
                this.filters.date = this.date;
            }

            if (!!this.shift) {
                this.filters.shift = this.shift;
            }

            if (this.notDeliveredOnly !== null) {
                this.filters.notDeliveredOnly = this.notDeliveredOnly;
            }
        },
        moment,
        fetchStorages() {
            StorageService.index()
                .then(({data}) => this.availableStorages = data)
                .catch(() => {
                    this.$bvToast.toast('Ошибка загрузки складов/цехов', {variant: 'danger'});
                });
        },
        fetchLastShippedDate() {
            this.lastShippedDateLoading = true
            this.$http
                .get(PRODUCTION_PLANS_ENDPOINT + '/last-shipped-date', {
                    params: {
                        without_loading: true
                    }
                })
                .then(response => response.data)
                .then(({lastShippedDate}) => {
                    this.lastShippedDate = lastShippedDate
                })
                .catch(error => {
                    this.$toast.error('Не удалось получить последнюю дату отгрузки')
                })
                .finally(() => {
                    this.lastShippedDateLoading = false
                })
        },
        delta(amount) {
            return this.required(amount) - this.delivered(amount);
        },
        required(amount) {
            return (parseFloat(amount.required_count) + parseFloat(amount.correction_count));
        },
        delivered(amount) {
            return parseFloat(amount.delivered_count);
        },
        datesEqual(first, second) {
            return moment(first).format('DD.MM.YYYY') === moment(second).format('DD.MM.YYYY');
        },
        getStorage(storageId) {
            let storage = this.availableStorages.filter(storage => {
                return storage.id === storageId;
            });

            if (!storage.length) {
                return null;
            }

            return storage[0];
        },
        getRowKey(row) {
            return (row.storageFrom ? row.storageFrom.id : '_') + '_' + row.storageTo.id;
        },
        applyFilters() {

            if (!!this.filters.shift && !!this.filters.shift.id && !!this.filters.date) {

                this.loading = true;

                this.$http.get(STORAGE_TASKS_ENDPOINT, {
                    params: {
                        date: moment(this.filters.date).format('YYYY-MM-DD'),
                        shiftId: this.filters.shift.id,
                        storage_to_ids: this.filters?.storageTo?.map((storage) => storage.id) ?? [],
                        storageFromId: this.filters.storageFrom ? this.filters.storageFrom.id : null,
                        notDeliveredOnly: this.filters.notDeliveredOnly ? 1 : 0,
                        productionPlanId: this.filters.productionPlan ? this.filters.productionPlan.id : null,
                        groupByStorageFrom: this.groupByStorageFrom ? 1 : 0,

                        without_loading: true,
                    }
                }).then(response => {
                    this.data = response.body.items;
                    this.productionPlans = response.body.productionPlans;
                    this.map = response.body.map;

                    this.loading = false;
                }, error => {

                    this.loading = false;

                    let message = '';

                    if (error.status === 422) {
                        if (error.body && error.body.errors) {
                            Object.keys(error.body.errors).map(key => {
                                let mess = error.body.errors[key];
                                if (Array.isArray(mess)) {
                                    message += mess.join('; ');
                                }
                            });
                        }
                    } else if (error.body.message) {
                        message = error.body.message;
                    }

                    this.$bvToast.toast("Ошибка! \n" + message, {variant: 'danger'});
                })
            }
        },
        transfer(amount) {
            if (this.transferIsBlocked(amount.production_plan_date)) {
                return;
            }

            this.$router.push({
                name: 'MaterialTransferActCreate',
                params: {
                    transfer: {
                        transmissionItems: [
                            {
                                nomenclature: amount.nomenclature,
                                count: this.delta(amount).toFixed(3)
                            }
                        ],
                        storageTo: this.getStorage(amount.storage_id),
                        storage: this.getStorage(amount.storage_from_id),
                        productionPlanDate: !!amount.production_plan_date ? amount.production_plan_date : null,
                        date: this.filters.date,
                        shift: this.filters.shift
                    }
                }
            });
        },
        transferRow(row_index) {
            let items = this.collectTransmissionItems(row_index);

            this.$router.push({
                name: 'MaterialTransferActCreate',
                params: {
                    transfer: {
                        transmissionItems: items,
                        storageTo: this.getStorage(this.data[row_index].storageTo.id),
                        storage: this.getStorage(this.data[row_index].storageFrom ? this.data[row_index].storageFrom.id : null),
                        productionPlanDate: !!this.selected[row_index.toString()].date ? this.selected[row_index.toString()].date : null,
                        date: this.filters.date,
                        shift: this.filters.shift
                    }
                }
            })
        },
        collectTransmissionItems(row_index) {
            let amount_indexes = this.selected[row_index].amounts;
            let items = [];

            if (Array.isArray(amount_indexes) && amount_indexes.length) {
                amount_indexes.map(amount => {
                    items.push({
                        nomenclature: this.data[row_index].amounts[amount].nomenclature,
                        count: this.delta(this.data[row_index].amounts[amount]).toFixed(3)
                    })
                });
            }

            return items;
        },
        openCorrection(amount, row_index, amount_index) {
            this.$modal.show(CreateCorrection, {
                requiredCount: parseFloat(amount.required_count),
                nomenclature: amount.nomenclature,
                productionPlanDate: amount.production_plan_date,
                storage: this.data[row_index].storageTo,
                shift: {
                    date: this.filters.date,
                    id: this.filters.shift.id
                },
                successCallback: (correction) => {
                    this.applyCorrectionCount(correction.count * parseInt(correction.reason.multiplier), row_index, amount_index);
                }
            }, {
                adaptive: true,
                scrollable: true,
                height: 'auto'
            })
        },
        applyCorrectionCount(count, row_index, amount_index) {
            let current_count = parseFloat(this.data[row_index].amounts[amount_index].correction_count);
            this.data[row_index].amounts[amount_index].correction_count = current_count + parseFloat(count);
        },
        storageBeingSelected(row_index) {
            return !!this.selected[row_index.toString()];
        },
        storageSelectionNotEmpty(row_index) {
            return this.storageBeingSelected(row_index) && this.selected[row_index.toString()].amounts.length > 0;
        },
        amountIsSelected(row_index, amount_index) {
            return this.storageBeingSelected(row_index) && this.selected[row_index.toString()].amounts.indexOf(amount_index) !== -1;
        },
        toggleStorageSelect(row_index) {
            if (!this.storageBeingSelected(row_index)) {
                this.selected[row_index.toString()] = {
                    amounts: [],
                    date: null,
                };
            } else {
                delete this.selected[row_index.toString()];
            }

            this.$forceUpdate();
        },
        toggleAmountSelect(row_index, amount_index) {

            if (!this.canSelectAmount(row_index, amount_index)) {
                return;
            }

            if (!!this.selected[row_index.toString()] && !!this.selected[row_index.toString()].amounts) {

                let index = this.selected[row_index.toString()].amounts.indexOf(amount_index);
                if (index !== -1) {
                    this.selected[row_index.toString()].amounts.splice(index, 1);

                    if (this.selected[row_index.toString()].amounts.length < 1) {
                        this.selected[row_index.toString()].date = null;
                    }

                } else {
                    this.selected[row_index.toString()].amounts.push(amount_index);
                    this.selected[row_index.toString()].date = this.data[row_index].amounts[amount_index].production_plan_date;
                }

            } else {
                this.selected[row_index.toString()].amounts = [amount_index];
                this.selected[row_index.toString()].date = this.data[row_index].amounts[amount_index].production_plan_date;
            }

            this.$forceUpdate();
        },
        canSelectAmount(row_index, amount_index) {
            let amount = this.data[row_index].amounts[amount_index];

            if (this.transferIsBlocked(amount.production_plan_date)) {
                return false;
            }

            if (!this.selected[row_index.toString()]) {
                return true;
            }

            if (!this.selected[row_index.toString()].date) {
                return true;
            }

            return this.selected[row_index.toString()].date === amount.production_plan_date;
        },
        transferIsBlocked(productionPlanDate) {
            if (this.lastShippedDateLoading) {
                return true;
            }
            if (productionPlanDate && this.lastShippedDate) {
                return moment(productionPlanDate) <= moment(this.lastShippedDate);
            }
            return false;
        }
    }
}
</script>

<style>
.no-border input {
    border: none !important;
}

.blocked {
    opacity: 0.65;
}

.yellow {
    background-color: #b0bd472b;
}

.bold {
    font-weight: bold;
}
</style>

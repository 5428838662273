<template>
    <multi-select
        label="name"
        track-by="id"
        select-label=""
        :multiple="multiple"
        :disabled="disabled"
        selected-label=""
        deselect-label=""
        :options="storages"
        :searchable="false"
        :show-labels="true"
        v-model="model"
        :close-on-select="true"
        placeholder="Склады пользователя"
        :open-direction="openDirection"
    />
</template>

<script>
import MultiSelect from 'vue-multiselect';
import {StorageService} from '@services';

export default {
    name: 'StorageSelect',
    components: {MultiSelect},
    props: {
        value: {
            type: Array,
            default: () => []
        },
        multiple: {
            type: Boolean,
            default: () => true,
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        checkAccess: {
            type: Boolean,
            default: () => false
        },
        allowedRoles: {
            type: Array,
            default: () => [],
        },
        openDirection: {
            type: String,
            default: () => 'top',
        },
        selectableForTaskList: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
    },
    data() {
        return {
            storages: [],
        }
    },
    methods: {
        fetchStorages() {
            const params = {
                limit: 100,
                filters: {},
            };

            if (this.allowedRoles) {
                params.filters.allowed_roles = this.allowedRoles || [];
            }

            if (this.selectableForTaskList) {
                params.filters.selectable_for_task_list = this.selectableForTaskList;
            }

            StorageService.index(params).then(({data}) => this.storages = data);
        }
    },
    mounted() {
        this.fetchStorages();
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
    <select placeholder="Выберите причину" @change="onChange()" class="form-control" v-model="reasonId" :disabled="isDisabled()">
        <option :disabled="!nullable" :value="null">Выберите причину</option>
        <option v-if="loading" :value="-1">Загрузка...</option>
        <option v-for="option in options" :value="option.id">{{option.name}}</option>
    </select>
</template>

<script>
    import {
        MATERIAL_TRANSFER_CORRECTION_REASON_ENDPOINT
    } from "../../../utils/endpoints";

    export default {
        name: "CorrectionReasonPicker",
        props: {
            value: {
                required: true,
            },
            nullable: {
                type: Boolean,
                required: false,
                default: false
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        watch: {
            value() {
                this.reasonId = this.value ? this.value.id : null;
            },
            loading(val) {
                if (val) {
                    this.reasonId = -1;
                }
            }
        },
        data() {
            return {
                reasonId: null,
                loading: false,
                options: []
            };
        },
        methods: {
            load() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.$http.get(MATERIAL_TRANSFER_CORRECTION_REASON_ENDPOINT, {
                    params: {
                        limit: 0,
                        without_loading: true
                    }
                }).then(response => {
                    this.options = response.data;
                }).catch(response => this.$root.responseError(response, 'Ошибка загрузки причин корректировки')).finally(() => {
                    this.loading = false;
                });
            },
            onChange() {
                this.$emit('input', this.options.find(p => p.id === this.reasonId));
            },
            isDisabled() {
                return this.disabled || this.loading;
            },
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>

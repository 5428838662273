<template>
    <div>
        <multiselect
            v-model="internalValue"
            :options="plans"
            :multiple="false"
            trackBy="id"
            label="label"
            select-label=""
            selected-label=""
            deselect-label=""
            deselected-label=""
            :max-height="400"
            :show-no-results="false"
            @search-change="search"
        >
            <template slot="noOptions">
                Ничего не найдено
            </template>
        </multiselect>
    </div>
</template>

<script>
    import {debounce} from 'lodash';
    import {PRODUCTION_PLANS_ENDPOINT} from "../../utils/endpoints";
    import Multiselect from 'vue-multiselect';

    export default {
        components: {
            Multiselect,
        },
        props: {
            value: {},
            disabled: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            internalValue() {
                this.$emit('input', this.internalValue);
            },
        },
        data() {
            return {
                internalValue: this.value,
                plans: [],
            }
        },
        methods: {
            search: debounce(function(input) {
                if (input !== '') {
                    this.$http.post(PRODUCTION_PLANS_ENDPOINT + '/find', {
                        query: input
                    }).then(response => {
                        this.plans = response.data.data.map(plan => {
                            plan['label'] = '#' + plan.id + ' ' + plan.nomenclature.name;
                            return plan;
                        });
                    })
                }

            }, 500),
        },
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

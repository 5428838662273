<template>
    <div class="card mb-0">
        <div class="card-header">
            <span>{{nomenclature.name}} ({{parseFloat(requiredCount).toFixed(3)}} {{nomenclature.measure_unit.name}})</span>
        </div>
        <div class="card-body">
            <b-form-group description="Причина корректировки">
                <correction-reason-picker :disabled="loading" v-model="item.reason"></correction-reason-picker>
                <error-list v-if="!!errors.correction_reason_id" :errors="errors.correction_reason_id" :message-function="errorMessageFormatter"></error-list>
            </b-form-group>
            <b-form-group description="Количество">
                <b-input-group :prepend="item.reason ? ( parseInt(item.reason.multiplier) > 0 ? '+' : '-' ) : null">
                    <b-form-input class="text-right" step="0" :disabled="loading" type="number" :formatter="numberFormatter" v-model="item.count"></b-form-input>
                </b-input-group>
                <error-list v-if="!!errors.count" :errors="errors.count" :message-function="errorMessageFormatter"></error-list>
            </b-form-group>
            <b-form-group description="Комментарий">
                <textarea :disabled="loading" class="form-control" v-model="item.comment"></textarea>
                <error-list v-if="!!errors.comment" :errors="errors.comment" :message-function="errorMessageFormatter"></error-list>
            </b-form-group>

            <b-form-group>
                <button :disabled="loading" class="btn btn-outline-danger btn-sm" @click="$emit('close')">Отмена</button>
                <button :disabled="loading" class="btn btn-success btn-sm pull-right" @click="save()">
                    <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                    Сохранить
                </button>
            </b-form-group>
            <b-form-group>
                <div v-if="history.length" class="table-responsive">
                    <table class="table table-bordered table-sm">
                        <thead class="thead-dark">
                        <tr>
                            <td>Причина</td>
                            <td>Кол-во</td>
                            <td>Дата</td>
                            <td>Комментарий</td>
                            <td>Автор</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="historyItem in history">
                            <td style="white-space: nowrap">{{historyItem.materialTransferCorrectionReason.name}}</td>
                            <td class="text-right">{{parseFloat(historyItem.count).toFixed(3)}}</td>
                            <td style="white-space: nowrap">{{moment(historyItem.created_at).format('DD.MM.YYYY HH:mm')}}</td>
                            <td>{{historyItem.comment ? historyItem.comment : '---'}}</td>
                            <td>{{historyItem.author.username}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else-if="history_loading" class="alert alert-info">Загрузка истории корректировок...</div>
                <div v-else class="alert alert-info">Нет ранее созданных корректировок</div>
            </b-form-group>
        </div>
    </div>
</template>

<script>
    import {correctionMixin} from "@mixins";
    import CorrectionReasonPicker from "../../_common/material-transfer/CorrectionReasonPicker";
    import ErrorList from "../../_common/ErrorList";
    import moment from 'moment';
    import {MATERIAL_TRANSFER_CORRECTION_ENDPOINT} from "../../../utils/endpoints";

    export default {
        name: "CreateCorrection",
        mixins: [correctionMixin],
        components: {CorrectionReasonPicker, ErrorList},
        props: {
            requiredCount: {
                required: true,
                type: Number
            },
            shift: {
                required: true,
                type: Object
            },
            storage: {
                required: true,
                type: Object,
            },
            nomenclature: {
                required: true,
                type: Object,
            },
            productionPlanDate: {
                required: true,
                type: String
            }
        },
        watch: {
            'item.reason': function (val) {
                this.item.correction_reason_id = !!val ? val.id : null
            },
        },
        data() {
            return {
                history: [],
                history_loading: false,
            }
        },
        methods: {
            moment,
            loadHistory() {
                if (this.history_loading) {
                    return;
                }

                this.history_loading = true;

                this.$http.get(MATERIAL_TRANSFER_CORRECTION_ENDPOINT + '/history', {
                    params: {
                        without_loading: true,

                        nomenclature_id: this.nomenclature.id,
                        storage_id: this.storage.id,
                        production_plan_date: moment(this.productionPlanDate).format('YYYY-MM-DD'),
                        shift: {
                            date: moment(this.shift.date).format('YYYY-MM-DD'),
                            id: this.shift.id,
                        },

                        with: [
                            'materialTransferCorrectionReason',
                            'author'
                        ]
                    }
                }).then(response => {
                    this.history = response.data;
                }).catch(response => this.$root.responseError(response, 'Ошибка загрузки истории корректировок'))
                    .finally(() => {
                    this.history_loading = false;
                });
            },
            success(response) {
                this.successCallback(this.item);
                this.$emit('close');
            }
        },
        mounted() {
            this.item = {
                amount: null,
                reason: null,
                count: 0,
                comment: '',

                storage_id: this.storage.id,
                nomenclature_id: this.nomenclature.id,
                production_plan_date: this.productionPlanDate,
                shift: {
                    id: this.shift.id,
                    date: moment(this.shift.date).format('YYYY-MM-DD')
                }
            };

            this.loadHistory();
        },
    }
</script>

<style scoped>

</style>
